import { GetStaticProps } from 'next';
import { Page404 } from '@/pages';
import { timeInSeconds } from '@/sharedLib';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: timeInSeconds.WEEK,
  };
};

export default Page404;
