import { AbsoluteCenter, Box, Center, Heading, Text } from '@chakra-ui/react';
import { NextPage } from 'next';

const Page404: NextPage = () => {
  return (
    <Box
      h='100vh'
      position='relative'
    >
      <AbsoluteCenter>
        <Center flexDirection='column'>
          <Text
            color='primary.base'
            data-testid='404'
            fontSize={150}
            textAlign='center'
            textDecoration='underline'
          >
            404
          </Text>

          <Heading as='h1'> Oops! Page not found</Heading>
          <Heading size='md'></Heading>
          <Text
            color='typography.base'
            mt={2}
            textAlign='center'
          >
            The link you followed is wrong or outdated.
          </Text>
        </Center>
      </AbsoluteCenter>
    </Box>
  );
};

export default Page404;
